import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "./layouts/Loading";
import MainMenu from "./layouts/menu/MainMenu";
import Index from "./layouts/menu/Index";

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App flex justify-center items-center bg-[#f6dddf]">
      <Router>
        {isLoading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/invitation/:uname" element={<MainMenu />} />
            <Route path="/loading" element={<Loading />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
