import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import bgInvitation from "../../assets/imgassetiwabri/bginvitationnew.png";
import logoIwabri from "../../assets/imgassetiwabri/logoiwabri.png";
import nameLine from "../../assets/imgassetiwabri/garisnama.png";
import whiteFlower from "../../assets/imgassetiwabri/bungaputih.png";
import topLeftLeaf from "../../assets/imgassetiwabri/daunkiriatas.png";
import blueFlower from "../../assets/imgassetiwabri/bungabiru.png";
import purpleFlower from "../../assets/imgassetiwabri/bungaungu.png";
import rightLeaf from "../../assets/imgassetiwabri/daunkanan.png";
import bottomRightLeaf from "../../assets/imgassetiwabri/daunkananbawah.png";
import data from "./data";
import useSound from "use-sound";
import bgm from "../../bgm/bgmiwabri2.wav";

function MainMenu() {
  const { uname } = useParams();
  const [play, { stop }] = useSound(bgm, { loop: true, volume: 0.5 });

  useEffect(() => {
    play();
    return () => stop();
  }, [play, stop]);

  // Find the record with the matching uname
  const record = data.find((item) => item.uname === uname);

  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [openBtn, setOpenBtn] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = bgInvitation;
    img.onload = () => {
      setImgDimensions({ width: img.width, height: img.height });
    };
  }, [bgInvitation]);
  const scroll1Ref = useRef(null);
  const scroll2Ref = useRef(null);
  const scroll3Ref = useRef(null);
  const scroll4Ref = useRef(null);
  const scroll5Ref = useRef(null);

  // Array of refs
  const sectionRefs = [
    scroll1Ref,
    scroll2Ref,
    scroll3Ref,
    scroll4Ref,
    scroll5Ref,
  ];

  // Track current section
  const [currentSection, setCurrentSection] = useState(0);

  // Scroll to the next or previous section
  const handleScrollToSection = (direction) => {
    let newSection = currentSection + direction;

    // Ensure the new section index is within bounds
    if (newSection >= 0 && newSection < sectionRefs.length) {
      setCurrentSection(newSection); // Update the current section
      handleScroll(sectionRefs[newSection]);
    }
  };

  const handleScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const btnOpen = () => {
    handleScrollToSection(1);
    setTimeout(() => {
      setOpenBtn(true);
    }, 800);
  };

  return (
    <div
      className="flex flex-col justify-center items-center text-center h-screen text-[#603467] font-acaslonproreguler"
      style={{
        backgroundImage: `url(${bgInvitation})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: imgDimensions.width ? `${imgDimensions.width}px` : "auto",
      }}
    >
      <main className="w-full h-[calc(100dvh-90px)] sm:h-[calc(100dvh-100px)] overflow-hidden overflow-y-auto no-scrollbar px-10">
        <p className="hidden">Testing</p>
        <section
          id="scroll1"
          ref={scroll1Ref}
          className="flex flex-col justify-center items-center min-h-full space-y-5 text-2xl"
        >
          <article className="flex flex-col items-center mt-5">
            <p className="">Yang Terhormat,</p>
            <p className="font-amithen text-5xl">Ibu {record.name}</p>
            <img src={nameLine} alt="" />
            <p className="">di tempat</p>
          </article>
          <article>
            <p className="">Dengan hangat mengundang </p>
            <p className="">kehadiran Ibu dalam acara: </p>
          </article>
          <article className="">
            <img src={logoIwabri} className="mt-5" alt="" />
          </article>
          {!openBtn && (
            <article>
              <button
                onClick={btnOpen}
                className="px-10 py-3 rounded-full bg-[#5e2769] text-white"
              >
                BUKA
              </button>
            </article>
          )}
        </section>
        <section
          id="scroll2"
          ref={scroll2Ref}
          className="flex flex-col items-center justify-center min-h-full space-y-1 text-xl"
        >
          <header className="space-y-3 flex flex-col mb-5">
            <img src={nameLine} alt="" />
            <p className="text-2xl font-acaslonprobold">INFORMASI ACARA</p>
            <img src={nameLine} alt="" />
          </header>
          <article>
            <p className="font-acaslonprobold">Tempat</p>
            <p>Sky Lounge Menara BRILiaN lt.38</p>
          </article>
          <article>
            <p className="font-acaslonprobold">Tanggal</p>
            <p>Kamis-Jumat, 12 - 13 Desember 2024</p>
          </article>
          <article>
            <p className="font-acaslonprobold">Pukul</p>
            <p>08:30 s.d selesai</p>
            <p>(Registrasi Peserta dimulai Pukul 07:30)</p>
          </article>
          <article>
            <p className="font-acaslonprobold">Konten Acara</p>
            <p>Merajut Kebersamaan IWABRI</p>
            <p>Sharing Session</p>
            <p>Woman Activity</p>
          </article>
        </section>
        <section
          id="scroll3"
          ref={scroll3Ref}
          className="flex flex-col items-center justify-center min-h-full space-y-1 text-lg"
        >
          <header className="space-y-3 flex flex-col w-64 mb-5">
            <img src={nameLine} alt="" />
            <p className="text-2xl font-acaslonprobold">DRESSCODE</p>
            <img src={nameLine} alt="" />
          </header>
          <article className="font-acaslonprobold">
            <p>Tanggal</p>
            <p>Kamis, 12 Desember 2024</p>
          </article>
          <article className="leading-6">
            <p>Atasan: Dominan Hitam</p>
            <p>(jika berbordir, harus bordir warna hitam)</p>
            <p>Bawahan: Wastra Nusantara</p>
            <p>(Kain Serut/Lilit, Rok, atau Kulot)</p>
            <p>Hijab: Nude Polos</p>
            <p>Sepatu: Bukan Sneakers atau bukan flat shoes</p>
          </article>
          <article className="font-acaslonprobold">
            <p>Jumat, 13 Desember 2024</p>
          </article>
          <article className="leading-6">
            <p>Atasan: Polos warna cerah</p>
            <p>(bukan hitam atau putih)</p>
            <p>Bawahan: Celana Blue Jeans</p>
            <p>Hijab: Polos tabrak warna</p>
            <p>dengan baju atasan</p>
            <p>Sepatu: Sneakers bebas</p>
          </article>
        </section>
        <section
          id="scroll4"
          ref={scroll4Ref}
          className="flex flex-col items-center justify-center min-h-full space-y-2 text-lg"
        >
          <header className="space-y-3 flex w-64 flex-col">
            <img src={nameLine} alt="" />
            <p className="text-2xl font-acaslonprobold">RUNDOWN</p>
            <img src={nameLine} alt="" />
          </header>
          <article className="font-acaslonprobold">
            <p>Tanggal</p>
            <p>Kamis, 12 Desember 2024</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">07:30 - 08:30</p>
            <p>Registrasi Peserta</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">08:30 - 09:30</p>
            <p>Opening & Arahan Ketua IWABRI Tingkat Pusat</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">09:30 - 11:30</p>
            <p>Sharing Session - dr. Elvine Gunawan, Sp.KJ</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">11:30 - 12:00</p>
            <p>Arahan Pembina Utama IWABRI</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">12:00 - 13:00</p>
            <p>ISHOMA</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">13:00 - 15:00</p>
            <p>Sharing Session - Didiet Maulana</p>
            <p>& Fashion Show</p>
          </article>
          <article className="leading-5">
            <p className="font-acaslonprobold">15:00 - 17:00</p>
            <p>Afternoon Tea with</p>
            <p>Three Ladies Band + Closing</p>
          </article>
        </section>
        <section
          id="scroll5"
          ref={scroll5Ref}
          className="flex flex-col items-center justify-center min-h-full space-y-5 text-2xl"
        >
          <header className="space-y-3 flex flex-col mb-8">
            <img src={nameLine} alt="" />
            <p className="text-2xl font-acaslonprobold">RUNDOWN</p>
            <img src={nameLine} alt="" />
          </header>
          <article className="font-acaslonprobold">
            <p>Tanggal</p>
            <p>Jumat, 13 Desember 2024</p>
          </article>
          <article className="leading-6">
            <p className="font-acaslonprobold">07:30 - 08:30</p>
            <p>Registrasi Peserta</p>
          </article>
          <article className="leading-6">
            <p className="font-acaslonprobold">08:30 - 08:45</p>
            <p>Opening</p>
          </article>
          <article className="leading-6">
            <p className="font-acaslonprobold">08:45 - 10:30</p>
            <p>Woman Activity - Merajut Bersama</p>
          </article>
          <article className="leading-6">
            <p className="font-acaslonprobold">10:30 - 12:00</p>
            <p>Closing + ISHOMA</p>
          </article>
        </section>
      </main>
      {openBtn &&
        currentSection !== 0 && ( // Hide when currentSection is 0
          <pre
            onClick={() => handleScrollToSection(-1)} // Move backward
            className="absolute top-2 bg-gradient-to-t from-gray-50 to-transparent px-10 animate-pulse"
          >
            <span className="text-lg">&#9650;</span>
            <p className="text-center text-lg">Ketuk disini untuk kembali</p>
          </pre>
        )}
      {openBtn && currentSection !== sectionRefs.length - 1 && (
        <pre
          onClick={() => handleScrollToSection(1)} // Move forward
          className="absolute bottom-[-10px] bg-gradient-to-b from-gray-50 to-transparent px-10 animate-pulse"
        >
          <p className="text-center text-lg">Ketuk disini untuk selanjutnya</p>
          <span className="text-lg">&#9660;</span>
        </pre>
      )}
      <img
        src={whiteFlower}
        className="absolute size-12 left-3 animate-fall"
        style={{ animationDelay: "5s" }} // Adjust delay for staggered effect
        alt=""
      />
      <img
        src={topLeftLeaf}
        className="absolute size-8 left-7 animate-fall"
        style={{ animationDelay: "3s" }}
        alt=""
      />
      <img
        src={blueFlower}
        className="absolute size-16 left-16 animate-fall"
        style={{ animationDelay: "0s" }}
        alt=""
      />
      <img
        src={purpleFlower}
        className="absolute size-16 right-5 animate-fall"
        style={{ animationDelay: "5.7s" }}
        alt=""
      />
      <img
        src={rightLeaf}
        className="absolute size-9 right-8 animate-fall"
        style={{ animationDelay: "3.7s" }}
        alt=""
      />
      <img
        src={bottomRightLeaf}
        className="absolute size-10 right-24 animate-fall"
        style={{ animationDelay: "0.7s" }}
        alt=""
      />
    </div>
  );
}

export default MainMenu;
