import React, { useEffect, useState } from "react";
import bgInvitation from "../assets/imgassetiwabri/bginvitationnew.png";
import nameLine from "../assets/imgassetiwabri/garisnama.png";

function Loading() {
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = bgInvitation;
    img.onload = () => {
      setImgDimensions({ width: img.width, height: img.height });
    };
  }, []);
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#f5a2d3] min-h-screen w-full font-amithen px-10"
      style={{
        backgroundImage: `url(${bgInvitation})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: imgDimensions.width ? `${imgDimensions.width}px` : "auto",
      }}
    >
      <p className="text-4xl text-[#603467] animate-pulse">Loading...</p>
      <img src={nameLine} alt="" />
    </div>
  );
}

export default Loading;
