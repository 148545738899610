// data.js
const data = [
  { uname: "IbuRenaSunarso", name: "Rena Sunarso" },
  { uname: "IbuNinaCaturBudiHarto", name: "Nina Catur Budi Harto" },
  { uname: "IbuKuswaryantiSupari", name: "Kuswaryanti Supari" },
  { uname: "IbuLillaAchmadSolichinL", name: "Lilla Achmad Solichin L." },
  { uname: "IbuYaniAgusNoorsanto", name: "Yani Agus Noorsanto" },
  { uname: "IbuApsariAgusSudiarto", name: "Apsari Agus Sudiarto" },
  { uname: "IbuDyahAgusWinardono", name: "Dyah Agus Winardono" },
  { uname: "IbuArintaAmamSukriyanto", name: "Arinta Amam Sukriyanto" },
  { uname: "IbuDhiniArgaMNugraha", name: "Dhini Arga M. Nugraha" },
  { uname: "IbuKatrinAndrijanto", name: "Katrin Andrijanto" },
  { uname: "IbuEtriAchmadRoyadi", name: "Etri Achmad Royadi" },
  { uname: "IbuYumeNyomanSugiriyasa", name: "Yume Nyoman Sugiriyasa" },
  { uname: "IbuTinaAestika", name: "Tina Aestika" },
  { uname: "IbuEviCandra", name: "Evi Candra" },
  { uname: "IbuSyifaStevenYudiyantho", name: "Syifa Steven Yudiyantho" },
  { uname: "IbuEvieYuliantoSetiawan", name: "Evie Yulianto Setiawan" },
  { uname: "IbuOlineHendyBernadi", name: "Oline Hendy Bernadi" },
  { uname: "IbuDeasyNinoDimasti", name: "Deasy Nino Dimasti" },
  { uname: "IbuPutriAhjaHaziqo", name: "Putri Ahja Haziqo" },
  { uname: "IbuEmilEddyTriWibowo", name: "Emil Eddy Tri Wibowo" },
  { uname: "IbuAyuIMadeJayaIndrawan", name: "Ayu I Made Jaya Indrawan" },
  { uname: "IbuLestariIssuhersatyo", name: "Lestari Issuhersatyo" },
  { uname: "IbuDianaHendradi", name: "Diana Hendradi" },
  { uname: "IbuOniAsepNurdin", name: "Oni Asep Nurdin" },
  { uname: "IbuAmiAgusSupriyono", name: "Ami Agus Supriyono" },
  { uname: "IbuWulanMochamadCholiq", name: "Wulan Mochamad Choliq" },
  { uname: "IbuIchaWindriyoAribowo", name: "Icha Windriyo Aribowo" },
  { uname: "IbuLindaImanNuraprianto", name: "Linda Iman Nuraprianto" },
  { uname: "IbuLindaDjakaDaniAmboro", name: "Linda Djaka Dani Amboro" },
  { uname: "IbuRiskaDanarWidyantoro", name: "Riska Danar Widyantoro" },
  { uname: "IbuEvaTeguhTofani", name: "Eva Teguh Tofani" },
  { uname: "IbuIkaSyafriRakhmat", name: "Ika Syafri Rakhmat" },
  { uname: "IbuCiciDanangWedhasmara", name: "Cici Danang Wedhasmara" },
  { uname: "IbuDinokBernadiKurniawan", name: "Dinok Bernadi Kurniawan" },
  { uname: "IbuIsnenGitoWardoyo", name: "Isnen Gito Wardoyo" },
  { uname: "IbuNuningSadmiadi", name: "Nuning Sadmiadi" },
  { uname: "IbuLiaEdiSetiawan", name: "Lia Edi Setiawan" },
  { uname: "IbuTataNovianSupriatno", name: "Tata Novian Supriatno" },
  { uname: "IbuJuliaNartoLaksono", name: "Julia Narto Laksono" },
  { uname: "IbuYeniHeryNoercahya", name: "Yeni Hery Noercahya" },
  {
    uname: "IbuNesimentiBangkitNgabdianto",
    name: "Nesimenti Bangkit Ngabdianto",
  },
  { uname: "IbuAdeHendraWinata", name: "Ade Hendra Winata" },
  { uname: "IbuIndahDjokoPurwanto", name: "Indah Djoko Purwanto" },
  { uname: "IbuWulanSuyitno", name: "Wulan Suyitno" },
  { uname: "IbuMelatiFikriSatriawan", name: "Melati Fikri Satriawan" },
  { uname: "IbuYuliMohSuratin", name: "Yuli Moh Suratin" },
  { uname: "IbuRiniAgusFirmansyah", name: "Rini Agus Firmansyah" },
  { uname: "IbuSorayaPramPurnama", name: "Soraya Pram Purnama" },
  { uname: "IbuZolandaAminTeguh", name: "Zolanda Amin Teguh" },
  { uname: "IbuYantiDArgoPrabowo", name: "Yanti D. Argo Prabowo" },
  { uname: "IbuSilviWahjuHidajat", name: "Silvi Wahju Hidajat" },
  { uname: "IbuDitaArieWibowo", name: "Dita Arie Wibowo" },
  { uname: "IbuDianRustamMaulana", name: "Dian Rustam Maulana" },
  { uname: "IbuEvaLuthfiIskandar", name: "Eva Luthfi Iskandar" },
  { uname: "IbuAstridOscarHutagaol", name: "Astrid Oscar Hutagaol" },
  { uname: "IbuFriesdaRolandHutagalung", name: "Friesda Roland Hutagalung" },
  { uname: "IbuLiaRizaPahlevi", name: "Lia Riza Pahlevi" },
  { uname: "IbuOomKusdinarWiraputra", name: "Oom Kusdinar Wiraputra" },
  { uname: "IbuRusiIsmanArdian", name: "Rusi Isman Ardian" },
  { uname: "IbuElyaRezaSyahrialS", name: "Elya Reza Syahrial S" },
  { uname: "IbuAdeEdiaHandiman", name: "Ade Edia Handiman" },
  { uname: "IbuEnnyHariBasuki", name: "Enny Hari Basuki" },
  { uname: "IbuRissaSetiyarta", name: "Rissa Setiyarta" },
  { uname: "IbuIndriatiFajarPrasetyo", name: "Indriati Fajar Prasetyo" },
  { uname: "IbuJunitJohnSarjono", name: "Junit John Sarjono" },
  { uname: "IbuRullyArisHartanto", name: "Rully Aris Hartanto" },
  { uname: "IbuWidiSutadi", name: "Widi Sutadi" },
  { uname: "IbuLinaWahyudiDarmawan", name: "Lina Wahyudi Darmawan" },
  { uname: "IbuEviPrimartonoGunawan", name: "Evi Primartono Gunawan" },
  { uname: "IbuTeaRachmatBudiLegowo", name: "Tea Rachmat Budi Legowo" },
  { uname: "IbuWindaAdeZulfikar", name: "Winda Ade Zulfikar" },
  { uname: "IbuHernaSonyHarsono", name: "Herna Sony Harsono" },
  {
    uname: "IbuAchiIdaBagusKetutSubagja",
    name: "Achi Ida Bagus Ketut Subagja",
  },
  { uname: "IbuMitaLukmanHakim", name: "Mita Lukman Hakim" },
  { uname: "IbuWitriUdinSalahudin", name: "Witri Udin Salahudin" },
  { uname: "IbuHestiDamar", name: "Hesti Damar" },
  { uname: "IbuMeriPrasetyaSayekti", name: "Meri Prasetya Sayekti" },
  { uname: "IbuHartiningsihYusronAvivi", name: "Hartiningsih Yusron Avivi" },
  { uname: "IbuIstiIndraBayuGunawan", name: "Isti Indra Bayu Gunawan" },
];

export default data;
